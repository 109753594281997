.custom-sidebar .MuiDrawer-paper {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}


.operations-menu-item>span {
  font-weight: bold;
  font-size: 13px;
}

.tasks-menu-item>span {
  // font-weight: bold;
  font-size: 13px;
}